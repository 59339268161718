<template>
    <div>


        <div class="mybet" v-for="(betinfo,index) in betList" :key="index">
            <div v-if="betinfo.isinplay === sportsConst.INPLAY_NOT">
                <div class="sports" v-for="(betInfoGame,index2) in betinfo.betInfoGameList" :key="index2">

                    <div class="match_area">
                        <div class="league mobile">
<!--                               <span v-if="betInfoGame.game.league.gameTypeId === sportsConst.GAME_TYPE_SOCCER"><img-->
<!--                                       src="../../assets/images/icon/football-ico.png"-->
<!--                                       style="width: 16px" alt=""> 축구 <i class="fa fa-angle-double-right"-->
<!--                                                                         style="padding: 0 5px"></i> </span>-->
<!--                            <span v-if="betInfoGame.game.league.gameTypeId === sportsConst.GAME_TYPE_BASEBALL"><img-->
<!--                                    src="../../assets/images/icon/baseball-ico.png"-->
<!--                                    style="width: 16px" alt=""> 야구 <i class="fa fa-angle-double-right"-->
<!--                                                                      style="padding: 0 5px"></i> </span>-->
<!--                            <span v-if="betInfoGame.game.league.gameTypeId === sportsConst.GAME_TYPE_BASKETBALL"><img-->
<!--                                    src="../../assets/images/icon/basketball-ico.png"-->
<!--                                    style="width: 16px" alt=""> 농구 <i class="fa fa-angle-double-right"-->
<!--                                                                      style="padding: 0 5px"></i> </span>-->
<!--                            <span v-if="betInfoGame.game.league.gameTypeId === sportsConst.GAME_TYPE_VOLLEYBALL"><img-->
<!--                                    src="../../assets/images/icon/volleyball-ico.png"-->
<!--                                    style="width: 16px" alt=""> 배구 <i class="fa fa-angle-double-right"-->
<!--                                                                      style="padding: 0 5px"></i> </span>-->
<!--                            <span v-if="betInfoGame.game.league.gameTypeId === sportsConst.GAME_TYPE_HOCKEY"><img-->
<!--                                    src="../../assets/images/icon/hockey-ico.png"-->
<!--                                    style="width: 16px" alt=""> 하키 <i class="fa fa-angle-double-right"-->
<!--                                                                      style="padding: 0 5px"></i> </span>-->
<!--                            <span v-if="betInfoGame.game.league.gameTypeId === sportsConst.GAME_TYPE_ESPORTS"><img-->
<!--                                    src="../../assets/images/icon/esport-ico.png"-->
<!--                                    style="width: 16px" alt=""> E-SPORTS <i class="fa fa-angle-double-right"-->
<!--                                                                            style="padding: 0 5px"></i> </span>-->
<!--                            <span v-if="betInfoGame.game.league.gameTypeId === sportsConst.GAME_TYPE_TENNIS"><img-->
<!--                                    src="../../assets/images/icon/tennis-ico.png"-->
<!--                                    style="width: 16px" alt=""> 테니스 <i class="fa fa-angle-double-right"-->
<!--                                                                       style="padding: 0 5px"></i> </span>-->
<!--                            <span v-if="betInfoGame.game.league.gameTypeId === sportsConst.GAME_TYPE_USA_FOOTBALL"><img-->
<!--                                    src="../../assets/images/icon/usa-football-ico.png"-->
<!--                                    style="width: 16px" alt=""> 미식축구 <i class="fa fa-angle-double-right"-->
<!--                                                                        style="padding: 0 5px"></i> </span>-->
                            <span class="st"
                                  style="float: left">{{betInfoGame.game.startTime|datef('MM/DD HH:mm')}}</span>
                            <img alt="" :src="betInfoGame.game.league.imageUrl" class="league_img">
                            <span class="league_name">{{betInfoGame.game.league.leagueName}}</span>

                            <span class="score text-orange"
                                  v-if="betInfoGame.game.gameResult !== sportsConst.GAME_RESULT_CANCELED
                                && betInfoGame.game.gameResult !== sportsConst.GAME_RESULT_SPECIAL
                                && betInfoGame.game.gameResult !== sportsConst.GAME_RESULT_WATING">
                                    {{betInfoGame.game.homeTeamScore}}:{{betInfoGame.game.awayTeamScore}}

                            </span>

                            <span class="score text-red"
                                  v-if="betInfoGame.game.gameResult === sportsConst.GAME_RESULT_CANCELED">
                                      취소
                                    </span>
                            <span class="score text-orange"
                                  v-if="betInfoGame.game.gameResult === sportsConst.GAME_RESULT_SPECIAL">
                                     적특
                                    </span>
                            <span class="score text-white"
                                  v-if="betInfoGame.game.gameResult === sportsConst.GAME_RESULT_WATING">
                                    대기중
                                    </span>

                        </div>
                        <div class="match clearfix">
                            <div class="start_time">
                                {{betInfoGame.game.startTime|datef('DD일 HH:mm')}}
                            </div>
                            <div class="team_info">
                                <div class="detail">
                                    <div class="home bg_1x2"
                                         :class="{'active_sports':betInfoGame.selectedVal === sportsConst.WAY_HOME}">
                                        <span class="home_name">{{betInfoGame.game.homeTeamName}}</span>
                                        <span class="home_odds">
                                            <i v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_HANDICAP"
                                               class="fa fa-h-square text-orange"></i>
                                            <i v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_OVERUNDER"
                                               class="fa fa-arrow-up text-blue"></i>
                                            {{betInfoGame.homeTeamOdds}}
                                        </span>
                                    </div>
                                    <!--무 3way-->
                                    <div class="draw bg_1x2"
                                         v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_1X2 && betInfoGame.drawOdds > 0"
                                         :class="{'active_sports':betInfoGame.selectedVal === sportsConst.WAY_DRAW}">
                                        <span class="draw_bedang">{{betInfoGame.drawOdds}}</span>
                                    </div>
                                    <div class="draw ho"
                                         v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_1X2 && betInfoGame.drawOdds <= 0">
                                        <span>vs</span>
                                    </div>
                                    <!--핸디캡 오버언더-->
                                    <div v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_HANDICAP"
                                         class="draw ho">
                                        {{betInfoGame.handicapVal}}
                                    </div>
                                    <div v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_OVERUNDER"
                                         class="draw ho">
                                        {{betInfoGame.overunderVal}}
                                    </div>

                                    <div class="away bg_1x2"
                                         :class="{'active_sports':betInfoGame.selectedVal === sportsConst.WAY_AWAY}">
                                        <span class="away_name">
                                          {{betInfoGame.game.awayTeamName}}
                                        </span>
                                        <span class="away_odds">
                                            {{betInfoGame.awayTeamOdds}}
                                            <i v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_HANDICAP"
                                               class="fa fa-h-square text-orange"></i>
                                            <i v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_OVERUNDER"
                                               class="fa fa-arrow-down text-green"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="more">
                                 <span class="text-skyblue" v-if="betInfoGame.betGameResult === 1">
                                       적중
                                  </span>
                                <span class="text-red" v-if="betInfoGame.betGameResult === 2">
                                            낙첨
                                </span>
                                <span class="text-orange" v-if="betInfoGame.betGameResult === 3">
                                     적특
                                  </span>
                                <span class="text-red" v-if="betInfoGame.betGameResult === 4">
                                     취소
                                  </span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div v-if="betinfo.isinplay === sportsConst.INPLAY_YES">
                <div class="sports" v-for="(betInfoGame,index2) in betinfo.betInfoGameList"
                     :key="index2">
                    <div class="match_area">
                        <div class="league">
<!--                            <span v-if="betInfoGame.game.league.gameTypeId === sportsConst.GAME_TYPE_SOCCER"><img-->
<!--                               src="../../assets/images/icon/football-ico.png"-->
<!--                               style="width: 16px" alt=""> 축구 <i class="fa fa-angle-double-right"-->
<!--                                                                 style="padding: 0 5px"></i> </span>-->
<!--                            <span v-if="betInfoGame.game.league.gameTypeId === sportsConst.GAME_TYPE_BASEBALL"><img-->
<!--                                    src="../../assets/images/icon/baseball-ico.png"-->
<!--                                    style="width: 16px" alt=""> 야구 <i class="fa fa-angle-double-right"-->
<!--                                                                      style="padding: 0 5px"></i> </span>-->
<!--                            <span v-if="betInfoGame.game.league.gameTypeId === sportsConst.GAME_TYPE_BASKETBALL"><img-->
<!--                                    src="../../assets/images/icon/basketball-ico.png"-->
<!--                                    style="width: 16px" alt=""> 농구 <i class="fa fa-angle-double-right"-->
<!--                                                                      style="padding: 0 5px"></i> </span>-->
<!--                            <span v-if="betInfoGame.game.league.gameTypeId === sportsConst.GAME_TYPE_VOLLEYBALL"><img-->
<!--                                    src="../../assets/images/icon/volleyball-ico.png"-->
<!--                                    style="width: 16px" alt=""> 배구 <i class="fa fa-angle-double-right"-->
<!--                                                                      style="padding: 0 5px"></i> </span>-->
<!--                            <span v-if="betInfoGame.game.league.gameTypeId === sportsConst.GAME_TYPE_HOCKEY"><img-->
<!--                                    src="../../assets/images/icon/hockey-ico.png"-->
<!--                                    style="width: 16px" alt=""> 하키 <i class="fa fa-angle-double-right"-->
<!--                                                                      style="padding: 0 5px"></i> </span>-->
<!--                            <span v-if="betInfoGame.game.league.gameTypeId === sportsConst.GAME_TYPE_ESPORTS"><img-->
<!--                                    src="../../assets/images/icon/esport-ico.png"-->
<!--                                    style="width: 16px" alt=""> E-SPORTS <i class="fa fa-angle-double-right"-->
<!--                                                                            style="padding: 0 5px"></i> </span>-->
<!--                            <span v-if="betInfoGame.game.league.gameTypeId === sportsConst.GAME_TYPE_TENNIS"><img-->
<!--                                    src="../../assets/images/icon/tennis-ico.png"-->
<!--                                    style="width: 16px" alt=""> 테니스 <i class="fa fa-angle-double-right"-->
<!--                                                                       style="padding: 0 5px"></i> </span>-->
<!--                            <span v-if="betInfoGame.game.league.gameTypeId === sportsConst.GAME_TYPE_USA_FOOTBALL"><img-->
<!--                                    src="../../assets/images/icon/usa-football-ico.png"-->
<!--                                    style="width: 16px" alt=""> 미식축구 <i class="fa fa-angle-double-right"-->
<!--                                                                        style="padding: 0 5px"></i> </span>-->
                            <img alt="" :src="betInfoGame.game.league.imageUrl" class="league_img">
                            <span class="league_name">{{betInfoGame.game.league.leagueName}}</span>
                            <span class="inplay_team_name">{{betInfoGame.game.homeTeamName}} vs {{betInfoGame.game.awayTeamName}}</span>
                        </div>
                        <ul>
                            <li>
                                <table class="table">
                                    <tr>
                                        <th>승부타임</th>
                                        <th>구분</th>
                                        <th>선택항목</th>
                                        <th>선택배당</th>
                                        <th>베팅결과</th>
                                    </tr>
                                    <tr>
                                        <td>{{betInfoGame.ito1}}</td>
                                        <td>{{betInfoGame.ito2}}</td>
                                        <td class="inplay_selected">{{betInfoGame.ito3}}</td>
                                        <td>{{betInfoGame.selectedOdds}} <span v-if="betInfoGame.score !== undefined"
                                                                               class="score">{{' ('+betInfoGame.score+')'}}</span>
                                        </td>
                                        <td>
                                        <span class="text-white" v-if="betInfoGame.betGameResult === 0">
                                                대기
                                              </span>
                                            <span class="text-skyblue" v-if="betInfoGame.betGameResult === 1">
                                                적중
                                         </span>
                                            <span class="text-red" v-if="betInfoGame.betGameResult === 2">
                                            미적중
                                          </span>
                                            <span class="text-orange" v-if="betInfoGame.betGameResult === 3">
                                             적특
                                          </span>
                                            <span class="text-orange" v-if="betInfoGame.betGameResult === 4">
                                             취소
                                          </span>
                                        </td>
                                    </tr>
                                </table>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="betInfo">
                <div style="width: 49%;display: flex;flex-wrap: nowrap;align-items: center;justify-content: flex-start">
                    베팅시간 <font style="margin-left: 10px">{{betinfo.createTime|datef('MM/DD HH:mm')}}</font>
                </div>
                <div style="width: 49%;display: flex;flex-wrap: nowrap;align-items: center;justify-content: flex-end">
                   <font style="margin-right: 5px">베팅결과:</font>
                    <font
                            v-if="betinfo.betResult === sportsConst.BET_RESULT_WAITING" class="text-white">결과대기</font>
                    <font class="text-skyblue"
                          v-if="betinfo.betResult === sportsConst.BET_RESULT_WIN"> 적중</font>
                    <font class="text-red"
                          v-if="betinfo.betResult === sportsConst.BET_RESULT_LOSE">미적중</font>
                    <font class="text-orange"
                          v-if="betinfo.betResult === sportsConst.BET_RESULT_SPECIALCASE">적특</font>
                    <font class="text-orange"
                          v-if="betinfo.betResult === sportsConst.BET_RESULT_CANCEL">취소
                            </font>

                </div>

                <div style="width:33%;display: flex;flex-wrap: nowrap;align-items: center;justify-content: flex-start">
                    배당 <font style="margin-left: 10px">{{betinfo.betOddsTotal}}</font>
                </div>

                <div style="width: 33%;display: flex;flex-wrap: nowrap;align-items: center;justify-content: center">
                    베팅금: <font style="margin-left: 10px">{{betinfo.betCash|comma}}</font>
                </div>
                <div style="width: 33%;display: flex;flex-wrap: nowrap;align-items: center;justify-content: flex-end">
                    예상당첨:<font style="margin-left: 10px">{{(betinfo.totalCash)|comma}}</font>
                </div>

            </div>
            <div style="display: flex;align-items: center;justify-content: space-between;border-bottom: 2px solid #ffffff;box-sizing: border-box;padding-bottom: 2px">
                <div>
                     <span class="chk">

<!--                  <input v-if="position" type="checkbox"-->
                         <!--                         @click="checkBetId(betinfo.id)">-->
                     <a href="javascript:void(0)" class="badge text-orange" style="margin-left: 6px;padding: 5px;"
                        v-if="betinfo.betResult === sportsConst.BET_RESULT_WAITING
                       && betinfo.isinplay === sportsConst.INPLAY_NOT"
                        @click="cancelBet(betinfo.id)">취소</a>
                    <a href="javascript:void(0)" class="badge" style="margin-left: 6px;padding: 5px;background-color: #ffa604"
                       v-if="betinfo.betResult !== sportsConst.BET_RESULT_WAITING"
                       @click="deleteBet(betinfo.id)">삭제</a>
                    </span>
                </div>
                <div>
                    <router-link v-if="position" tag="button" :to="{path: '/customer_center', query: {betid: betinfo.id}}"
                                 class="badge" style="padding: 5px;background-color: #f4c600;color: #ffffff">베팅첨부 문의
                    </router-link>
                </div>


            </div>
        </div>
        <div style="display: flex;align-items: center;justify-content: flex-end;margin-top: 10px">
            <button v-if="position!=0" class="badge" style="padding: 5px;margin: 5px;border-radius: 1px;background-color: #ffa604"
                    @click="deleteAllBetInfo">
               전체삭제
            </button>
        </div>

    </div>

</template>

<script>
    import {cancelBet, deleteAllBet, deleteBet} from "../../network/sportsBetRequest";
    import sportsConst from "../../common/sportsConst";
    import BoardWriter from "../BoardWriter";
    import {delQuestion} from "../../network/userRequest";

    export default {
        name: "SportsBetListComp",
        components: {BoardWriter},
        data() {
            return {
                sportsConst,
                betIds: [],
            }
        },
        props: {
            betList: {
                type: Array,
                default: []
            },
            position: {
                type: Number,
                default: 1 // 0:게시글에등록된 베팅내역(삭제,취소,선택체크 를 숨김) 1: 베팅내역 리스트
            }
        },
        methods: {
            del(id) {
                delQuestion(id).then(res => {
                    if (res.data.success) {
                        this.initQuestion()
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            checkBetId(id) {
                let index = this.betIds.findIndex((item) => {
                    return item === id
                });
                if (index === -1) {
                    this.betIds.push(id)
                } else {
                    this.betIds.splice(index, 1)
                }
                this.$emit('betInfoChecked', this.betIds)
            },
            cancelBet(id) {
                this.$swal({
                    title: '베팅내역을 취소하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {
                        cancelBet(id).then(res => {
                            if (res.data.success) {
                                this.$emit('cancelOrDelBet')
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })


            },
            deleteBet(id) {

                this.$swal({
                    title: '베팅내역을 삭제하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {
                        deleteBet(id).then(res => {
                            if (res.data.success) {
                                this.$emit('cancelOrDelBet')
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })
            },
            deleteAllBetInfo() {
                this.$swal({
                    title: '전체 베팅내역을 삭제하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {
                        deleteAllBet().then(res => {
                            if (res.data.success) {
                                this.$emit('cancelOrDelBet')
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })
            },

        }
    }
</script>

<style scoped>
    .league .score {
        float: right;
        margin: 0 3px;
    }

    .league .st {
        display: none;
    }

    .betInfo {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        background-color: var(--noticeBg);
        color: #fff9ff;
    }
    .betInfo div{
        height: 28px;
        box-sizing: border-box;
        padding: 0 5px;
    }
    .betInfo span {
        display: block;
        width: 33.3%;
        color: #c4c4c3;
        box-sizing: border-box;
        padding-left: 3px;
    }

    .match_area table {
        font-size: 12px;
    }


    .match_area table th, td {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #1a4050;
    }

    .match_area .inplay_team_name {
        margin-left: 20px;
        color: #f4c600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @media screen and (max-width: 1024px) {
        .league .st {
            display: inline-block;
            color: #c4c4c3;
            margin-right: 20px;
        }

        .match_area .inplay_team_name {
            margin-left: 5px;
        }
    }


</style>